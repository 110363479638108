import axios from "axios";

export const API_PATH = "https://api.posx.3dboosterstudio.com/api/";
//export const API_PATH = "http://127.0.0.1:8000/api/";
export const WEB_PATH = "https://posx.3dboosterstudio.com";
export const API_TOKEN = "postoken_fsc";

export const checktoken = async (uid) => {
  const url = API_PATH + "checkacc";
  try {
    const formData = new FormData();
    formData.append("uid", uid);
    const data = await axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getProducts = async (uid) => {
  const url = API_PATH + "products";
  try {
    const formData = new FormData();
    formData.append("uid", uid);
    const data = await axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const adPOS = async (uid, form) => {
  const url = API_PATH + "addpos";
  try {
    const formData = new FormData();
    formData.append("uid", uid);
    formData.append("receiptNo", form["receiptNo"]);
    formData.append("customer", form["customer"]);
    formData.append("payment_method", form["payment_method"]);
    formData.append("cash", form["cash"]);
    formData.append("change", form["change"]);
    formData.append("cart", JSON.stringify(form["cart"]));
    formData.append("paymth", JSON.stringify(form["paymth"]));
    const data = await axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const simplePost = async (module_url) => {
  const url = API_PATH + module_url;
  try {
    const data = await axios
      .post(
        url,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const adCustomer = async (uid, form) => {
  const url = API_PATH + "adcustomer";
  try {
    const formData = new FormData();
    formData.append("uid", uid);
    for (const i in form) {
      formData.append(i, form[i]);
    }
    const data = await axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export default API_PATH;
