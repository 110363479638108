<template>
  <div class="box-error"><span>error 404</span></div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  color: #a71c1c;
}
.box-error {
  background: url("../../public/503.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.box-error span {
  display: none;
}
</style>
